import { FaHeartbeat } from "react-icons/fa";
import Stat from '../../components/Stat';

export default function DroneStateStat(props) {
    if (props.offline || props.status === 'n/a') {
        return (
            <Stat
                label="State"
                text={<span className='text-secondary'>Offline</span>}
                icon={
                    <FaHeartbeat
                        className="ms-2 text-secondary"
                        style={{
                            marginTop: "-2px",
                        }}
                    />
                }
            />
        );
    } else if (props.status.length > 21 || props.status === 'Invalid') {
        // Cut the first 21 characters of the status string
        return (
            <Stat
                label="State"
                text={<span className='text-success'>{
                    props.status.length > 21 ? props.status.substring(0, 21) : props.status
                }</span>}
                icon={
                    <FaHeartbeat
                        className="ms-2 text-success"
                        style={{
                            marginTop: "-2px",
                        }}
                    />
                }
            />
        );
    } else if (props.status?.toLowerCase() === 'sleeping') {
        return (
            <Stat
                label="State"
                text={<span className='text-secondary'>Sleeping</span>}
                icon={
                    <FaHeartbeat
                        className="ms-2 text-secondary"
                        style={{
                            marginTop: "-2px",
                        }}
                    />
                }
            />
        );
    } else {
        return (
            <Stat
                label="State"
                text={<span className='text-success'>{props.status}</span>}
                icon={
                    <FaHeartbeat
                        className="ms-2 text-success"
                        style={{
                            marginTop: "-2px",
                        }}
                    />
                }
            />
        );
    }
}
