import Stat from '../../components/Stat';
import { ProgressBar } from 'react-bootstrap';

export default function DroneDiskSpaceStat({ availableMB, totalMB, minAvailableMB }) {
    if (availableMB === undefined || totalMB === undefined) {
        return null;
    }

    minAvailableMB = minAvailableMB ?? 0;

    const dangerMB = Math.max(minAvailableMB, 1024);
    const warnMB = Math.max(2 * minAvailableMB, 2048);
    const variant = availableMB < dangerMB ? "danger" : (availableMB < warnMB ? "warning" : "success");

    // We do the rounding before calculating usedMB to ensure that also for rounded numbers: used + available == total
    // This to prevent situations similar to 1.5 + 2.5 = 4 would result in 2 + 3 = 4
    // Round GBs to 2 digits and MBs to integers
    const roundMB = (mb) => mb >= 1024 ? Math.round((mb / 1024) * 100) * 1024 / 100 : Math.round(mb);

    availableMB = roundMB(availableMB);
    totalMB = roundMB(totalMB);
    const usedMB = roundMB(totalMB - availableMB)
    const usedPercentage = (usedMB) / totalMB * 100

    // mb is already rounded so no need to round again
    const formatSize = (mb) => mb >= 1024 ? `${mb / 1024} GB` : `${mb} MB`;

    const usedText = formatSize(usedMB);
    const availableText = formatSize(availableMB);
    const totalText = formatSize(totalMB);

    return <Stat
        label='Disk space available'
        text={<span className={`text-${variant}`}>{`${availableText}`}</span>}
        icon={
            <div style={{ minWidth: 170, textAlign: 'center' }}>
                <ProgressBar now={usedPercentage} className='mb-1' />
                <h6 style={{ fontWeight: 'normal' }}>{`${usedText} / ${totalText}`}</h6>
            </div>
        }
    />
};
