import React, { createContext, useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import * as api from "../api/api";

const DroneOnlineContext = createContext(null);
const DEBOUNCE_TIME = 2000; // 2 seconds before final unregistration

export const DroneOnlineProvider = ({ children }) => {
    const [refCounts, setRefCounts] = useState(new Map()); // { droneId → refCount }
    const [trackedDroneIds, setTrackedDroneIds] = useState(new Set()); // Set of droneIds that need polling
    const [isOnlineStatuses, setIsOnlineStatuses] = useState(new Map()); // { droneId → isOnline }

    const pendingChanges = useRef([]); // List of pending registrations and unregistrations
    const updateTimeout = useRef(null);

    const fetchOnlineStatus = useCallback(async (droneId) => {
        try {
            const data = await api.isDroneOnline(droneId);
            return data;
        } catch (err) {
            console.error(`Error checking drone ${droneId}:`, err);
            return { isOnline: false };
        }
    }, []);

    const pollDrones = useCallback(async () => {
        if (trackedDroneIds.size === 0) return;

        console.log(`[${new Date().toISOString()}] Checking online status of drones:`, Array.from(trackedDroneIds));

        const statusUpdates = await Promise.all(
            Array.from(trackedDroneIds).map(async (droneId) => {
                const status = await fetchOnlineStatus(droneId);
                return { droneId, isOnline: status.isOnline };
            })
        );

        setIsOnlineStatuses(() => {
            const updates = new Map();
            statusUpdates.forEach(({ droneId, isOnline }) => {
                updates.set(droneId, isOnline);
            });
            return updates;
        });
    }, [trackedDroneIds, fetchOnlineStatus]);

    useEffect(() => {
        // Poll immediately for fast feedback and then poll periodically
        pollDrones();
        const interval = setInterval(() => {
            pollDrones();
        }, 1000);

        return () => clearInterval(interval);
    }, [pollDrones]);

    useEffect(() => {
        const newTracked = new Set();
        refCounts.forEach((count, droneId) => {
            if (count > 0) {
                newTracked.add(droneId);
            }
        });
        setTrackedDroneIds((prev) => {
            if (prev.size === newTracked.size && [...prev].every(id => newTracked.has(id))) {
                return prev; // Tracked drone ids haven't changed, no need to update
            }
            console.log("Tracked drones updated:", newTracked)
            return newTracked;
        });
    }, [refCounts]);

    const processPendingChanges = useCallback(() => {
        if (pendingChanges.current.length === 0) return;

        // Make a copy of the pending changes and clear so no updates will be missed
        const changesToProcess = [...pendingChanges.current];
        pendingChanges.current = [];

        // Process all changes in one update
        setRefCounts((prev) => {
            const newCounts = new Map(prev);
            changesToProcess.forEach(({ droneId, action }) => {
                if (action === "register") {
                    newCounts.set(droneId, (newCounts.get(droneId) || 0) + 1);
                } else if (action === "unregister") {
                    const count = newCounts.get(droneId) || 0;
                    if (count > 1) {
                        newCounts.set(droneId, count - 1);
                    } else {
                        newCounts.delete(droneId);
                    }
                }
            });
            return newCounts;
        });
    }, []);

    // We use a short delay here to batch multiple registrations into one update
    const scheduleProcessing = useCallback(() => {
        if (!updateTimeout.current) {
            updateTimeout.current = setTimeout(() => {
                updateTimeout.current = null;
                processPendingChanges();
            }, 10);
        }
    }, [processPendingChanges]);

    const registerDrone = useCallback((droneId) => {
        if (!droneId) return; // Ignore empty strings
        // console.log(`Registering drone ${droneId}`);
        pendingChanges.current.push({ droneId, action: "register" });
        scheduleProcessing();
    }, [scheduleProcessing]);

    const unregisterDrone = useCallback((droneId) => {
        if (!droneId) return; // Ignore empty strings
        // console.log(`Unregistering drone ${droneId}`);

        // We schedule unregistering to happen after debounce time. In this way, if a new registration
        // is coming in before the end of the debounce time, the droneId will be kept tracked.
        // This is mainly done for components which are rerendered and are unregistering and registering very often
        setTimeout(() => {
            pendingChanges.current.push({ droneId, action: "unregister" });
            scheduleProcessing();
        }, DEBOUNCE_TIME);
    }, [scheduleProcessing]);

    return (
        <DroneOnlineContext.Provider value={{ isOnlineStatuses, registerDrone, unregisterDrone }}>
            {children}
        </DroneOnlineContext.Provider>
    );
};

export const useDroneOnline = (droneId) => {
    const { isOnlineStatuses, registerDrone, unregisterDrone } = useContext(DroneOnlineContext);

    useEffect(() => {
        registerDrone(droneId);
        return () => unregisterDrone(droneId);
    }, [droneId, registerDrone, unregisterDrone]);

    return isOnlineStatuses.get(droneId) ?? false;
};

export const useDronesOnline = (droneIds) => {
    const { isOnlineStatuses, registerDrone, unregisterDrone } = useContext(DroneOnlineContext);

    // Ensure stable reference for sorted and unique drone IDs
    const stableDroneIds = useMemo(() => [...new Set(droneIds)].sort(), [droneIds]);

    useEffect(() => {
        stableDroneIds.forEach(registerDrone);
        return () => {
            stableDroneIds.forEach(unregisterDrone);
        };
    }, [stableDroneIds, registerDrone, unregisterDrone]);

    return Object.fromEntries(stableDroneIds.map(droneId => [droneId, isOnlineStatuses.get(droneId) ?? false]));
};
