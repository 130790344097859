import React from "react";
import Countdown from "react-countdown";
import { Title } from "../../components/Layout/Layout";
import * as api from "../../api/api";
import { Card, Container, Row, Col, Image, Alert } from "react-bootstrap"
import { LivestreamComponent } from "../LivestreamPage";
import { DroneStatus } from "../DronePage";
import MissionList from "../../components/MissionList";
import { useUser } from "../../contexts/user_provider";
import { useDroneOnline } from "../../contexts/DroneOnlineProvider";
var parser = require("cron-parser");

export default function DemoPage(props) {
    const queryParams = new URLSearchParams(props.location.search);
    const organizationId = queryParams.get('organizationId');
    const locationId = queryParams.get('locationId');
    const routineId = queryParams.get('routineId');
    const routineTimeOffset = 15 * 1000; // ms to go live after routine starts
    const [routine, setRoutine] = React.useState();
    const [currentTime, setCurrentTime] = React.useState(undefined);
    const [droneId, setDroneId] = React.useState('');
    const [droneFeedback, setDroneFeedback] = React.useState(undefined);
    const [isDemoActive, setIsDemoActive] = React.useState(false);
    const [isFlying, setIsFlying] = React.useState(false);
    const [waitForFlying, setWaitForFlying] = React.useState(false);
    const [missions, setMissions] = React.useState([]);
    const [timezone, setTimezone] = React.useState('UTC');
    const { getUserIsAdmin } = useUser();
    const timeoutRef = React.useRef(null);
    const isDroneOnline = useDroneOnline(droneId)

    function getDate(interval, timezone) {
        const intervalIterator = parser.parseExpression(interval, { tz: timezone });
        return intervalIterator.next()._date;
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <>
                {completed && "Starting"}
                {days > 0 && `${days}d `}
                {hours > 0 && `${hours}h `}
                {minutes > 0 && `${minutes}m `}
                {seconds > 0 && `${seconds}s `}
            </>
        );
    };

    const CorvusLogo = ({ width, className }) => {
        return <Image
            src={process.env.PUBLIC_URL + "/CorvusLogo.png"}
            width={width}
            className={className}
            alt="Corvus Drones logo"
        />
    }

    React.useEffect(() => {
        api.subscribeRoutine(organizationId, locationId, routineId, setRoutine);
    }, [organizationId, locationId, routineId]);

    React.useEffect(() => {
        api.getTimezone(organizationId, locationId).then((timezone) => { setTimezone(timezone); });
    }, [organizationId, locationId]);

    React.useEffect((props) => {
        if (routine && routine.interval) {
            setCurrentTime(getDate(routine.interval, timezone) + routineTimeOffset);
        }

        if (routine && routine.droneId) {
            setDroneId(routine.droneId);
        }
    }, [routine, timezone, routineTimeOffset]);

    React.useEffect(() => {
        if (droneId) {
            return api.subscribeDroneFeedback(droneId.toUpperCase(), setDroneFeedback);
        }
    }, [droneId]);

    React.useEffect(() => {
        if (organizationId && locationId && droneId) {
            return api.subscribeDroneMissions(organizationId, locationId, droneId, setMissions, 3);
        }
    }, [organizationId, locationId, droneId]);

    React.useEffect(() => {
        setIsFlying(droneFeedback?.widgets ? api.droneIsFlying(droneFeedback.widgets) : false);
    }, [droneFeedback])

    React.useEffect(() => {
        if (isFlying) {
            setIsDemoActive(true);
            setWaitForFlying(false);
            // Clear any existing timeout when the drone is flying
            if (timeoutRef.current) {
                console.log('Reset timeout 1');
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
        } else if (isDemoActive && !waitForFlying) {
            // Set a timeout to deactivate demo mode after 15 seconds if not flying
            if (!timeoutRef.current) {
                console.log('Set timeout');
                timeoutRef.current = setTimeout(() => {
                    console.log('Demo timed out');
                    timeoutRef.current = null;
                    setIsDemoActive(false);
                }, 15000);
            }
        }

        // Clear timeout on component unmount
        return () => {
            if (timeoutRef.current) {
                console.log('Reset timeout 2');
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
        };
    }, [isFlying, isDemoActive, waitForFlying])

    if (!organizationId || !locationId || !routineId) {
        return <Container style={{ maxWidth: "850px" }} className="mb-5 mt-4">
            <Card>
                <Card.Body className='p-4 pt-0 mt-4'>
                    No organizationId, locationId and/or routineId. Access this page from a routine page.
                </Card.Body>
            </Card>
        </Container>;
    }

    if (!routine) {
        return <div>Loading...</div>;
    }

    return (
        <Container style={{ maxWidth: "850px" }} className="mb-5 mt-4">
            <Card>
                {getUserIsAdmin &&
                    <>
                        {isDemoActive && <Title title={`Drone ${droneId}`}>
                            <CorvusLogo width="20%" />
                        </Title>}
                        <Card.Body className='p-4 pt-0 mt-0'>
                            <>
                                {isDemoActive && (
                                    <>
                                        {isDroneOnline &&
                                            <>
                                                {droneFeedback &&
                                                    droneFeedback.widgets &&
                                                    Object.keys(droneFeedback.widgets).length > 0 &&
                                                    <div className="mb-4">
                                                        <DroneStatus
                                                            droneStatus={droneFeedback.widgets.status}
                                                        />
                                                    </div>
                                                }
                                                {droneId &&
                                                    <LivestreamComponent
                                                        droneId={droneId}
                                                        cameraName="Payload"
                                                    />}
                                                <MissionList
                                                    className="mt-4"
                                                    organizationId={organizationId}
                                                    locationId={locationId}
                                                    missions={missions}
                                                />
                                            </>
                                        }
                                        {!isDroneOnline &&
                                            <Alert className="text-center mb-0" variant="secondary">
                                                <div className="text-muted">
                                                    Drone is offline
                                                </div>
                                            </Alert>
                                        }
                                    </>
                                )}
                                {!isDemoActive && (
                                    <Container fluid className="d-flex flex-column justify-content-center align-items-center text-center mt-4">
                                        <Row>
                                            <Col>
                                                <CorvusLogo width="40%" className={"mb-4"} />
                                                {routine.enabled &&
                                                    <>
                                                        <h1 className="display-1"><b>Next demo</b></h1>
                                                        <code className="display-2">
                                                            {currentTime &&
                                                                <Countdown
                                                                    date={currentTime}
                                                                    key={currentTime}
                                                                    renderer={renderer}
                                                                    autoStart={true}
                                                                    intervalDelay={0}
                                                                    onComplete={() => {
                                                                        setIsDemoActive(true);
                                                                        setWaitForFlying(true);
                                                                        // Wait 1 second before updating the current time to ensure we set it to the next interval
                                                                        setTimeout(() => {
                                                                            setCurrentTime(
                                                                                getDate(routine.interval) + routineTimeOffset
                                                                            );
                                                                        }, 1000);
                                                                    }}
                                                                />}
                                                        </code>
                                                    </>
                                                }
                                                {!routine.enabled &&
                                                    <>
                                                        <h1 className="display-1"><b>Next demo</b></h1>
                                                        <code className="display-2">not scheduled</code>
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                )}
                            </>
                        </Card.Body>
                    </>
                }
                {!getUserIsAdmin &&
                    <>
                        <Title title={"Demo page"}>
                            <CorvusLogo width="20%" />
                        </Title>
                        <Card.Body>
                            <Alert variant="danger">
                                This page can only be accessed with administrator rights
                            </Alert>
                        </Card.Body>
                    </>
                }
            </Card>
        </Container>

    );
};
